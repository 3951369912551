<template>
    <div>

        <el-main style=" padding-top: 5px;padding-bottom: initial  ">
            <el-form label-width="80px" @submit.native.prevent>
                <el-row>
                    <el-col :lg="24">
                        <div :class='[this.$store.getters.getRunTimeTest ? "warning" : "tip"]'>
                            <el-row>
                                <el-col :lg="24" style="line-height: 27px;">
                                    <strong style=" font-size: 16px; ">{{this.$store.getters.getRunTimeTest ?'(测试环境,数据定期删除)':''}}当前：应收账款明细</strong>
                                </el-col>
                            </el-row>
                        </div>
                    </el-col>
                    <el-col :lg="24">
                        <el-row>
                            <el-col :lg="4" :sm="12" :md="8" v-show="true">
                                <el-date-picker
                                        style=" margin-top: 8px; width: 220px"
                                        v-model="pickerDate"
                                        type="daterange"
                                        size="mini"
                                        :clearable="false"
                                        :picker-options="pickerOptions"
                                        range-separator="至"
                                        start-placeholder="开始日期"
                                        end-placeholder="结束日期"
                                        align="right">
                                </el-date-picker>
                            </el-col>
                            <el-col :sm="12" :md="10">
                                <el-button style=" margin-top: 8px; " type="primary" round
                                           @click="searchReceivables" size="mini">搜索
                                </el-button>
                                <el-button v-if="$_getActionKeyListBoolean(['/transferSlip'])" style=" margin-top: 8px; " type="primary" round :disabled="$refs.xGrid == null || $refs.xGrid.getCurrentRecord() == null"
                                           @click="openTheWarehouse($refs.xGrid.getCurrentRecord())" size="mini">查询销售单
                                </el-button>
                                <el-button v-if="$_getActionKeyListBoolean(['/transferSlip'])" style=" margin-top: 8px; " type="primary" round :disabled="$refs.xGrid == null || $refs.xGrid.getCurrentRecord() == null"
                                           @click="$_openBill($refs.xGrid.getCurrentRecord(),'print')" size="mini">打印销售单
                                </el-button>
                                <el-button type="primary" size="mini" @click="$router.back()" style="margin-bottom: 2px;margin-top: 2px">返回
                                </el-button>
                                <el-button type="warning" size="mini" @click="printSelectEvent">打印选中</el-button>
                                <el-button type="warning" size="mini" @click="printEvent2">高级打印</el-button>
                                <el-checkbox style="padding-left: 7px" v-model="formData.showAllBalance"
                                             @change="getReceivables()">显示无欠款
                                </el-checkbox>
                            </el-col>
                            <el-col :lg="6" :sm="12" :md="8">
                                <el-row>
                                    <el-col class="pull-left" :lg="4">
                                        <vxe-toolbar class="pull-left" style=" height: 42px; " custom export
                                                     ref="xToolbar2">
                                        </vxe-toolbar>
                                    </el-col>

                                </el-row>
                            </el-col>
                        </el-row>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :lg="24">
                        <!--:merge-cells="mergeCells"-->
                        <!--:span-method="mergeRowMethod"-->
                        <vxe-grid
                                border
                                resizable
                                show-overflow
                                keep-source
                                id="xGrid"
                                ref="xGrid"
                                align="center"
                                size="mini"
                                :height="(this.getViewHeight() - 130)+''"
                                :print-config="{}"
                                :export-config=tableExport
                                :custom-config="{storage:true}"
                                highlight-hover-row
                                highlight-current-row
                                :checkbox-config="{labelField:'id',highlight: true,reserve: true,range:true}"
                                @cell-click="cellClickEvent"
                                :row-class-name="$_tableRowClassName"
                                :data="tableDataMain"
                                :columns="tableColumn"
                                show-footer
                                :merge-cells="mergeCells"
                                :mouse-config="{selected: false}"
                                :footer-method="footerMethod"
                                :edit-config="{showIcon:false,trigger: 'click', mode: 'cell', showStatus: true}"
                                :keyboard-config="{enterToTab:true,isArrow: true, isDel: false, isEnter: true, isTab: true,isEdit:true}">
                            <template #pager>
                                <vxe-pager
                                        :layouts="['Sizes', 'PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'FullJump', 'Total']"
                                        :page-sizes="[50,500,1000,5000,10000]"
                                        :current-page.sync="tablePage.currentPage"
                                        :page-size.sync="tablePage.pageSize"
                                        :total="tablePage.total"
                                        @page-change="handlePageChange">
                                </vxe-pager>
                            </template>
                        </vxe-grid>
                    </el-col>
                </el-row>

            </el-form>
        </el-main>

    </div>

</template>

<script>
    import XEUtils from 'xe-utils'
    import {filterRender} from "@/utils/gird-filter";


    export default {


        data() {
            // 通用行合并函数（将相同多列数据合并为一行）

            return {
                /* {row: 1, col: 9, rowspan: 2, colspan: 1},*/
                mergeCells: [],
                formData: {//搜索筛选
                    showCancel:false,
                    showAllBalance:false,
                    name: null,
               },
                tablePage: {
                    total: 0,
                    currentPage: 1,
                    pageSize: 500,
               },
                pickerOptions: {
                    shortcuts: [{
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                       }
                   }, {
                        text: '最近一个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                       }
                   }, {
                        text: '最近三个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                       }
                   }, {
                        text: '最近一年',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
                            picker.$emit('pick', [start, end]);
                       }
                   },  {
                        text: '本月',
                        onClick(picker) {
                            var now = new Date();
                            var year = now.getFullYear();
                            var month = now.getMonth() + 1;
                            if (month < 10) {
                                month = '0' + month;
                           }
                            // 本月第一天
                            var startDate = new Date(year + '-' + month + '-01 00:00:00');
                            picker.$emit('pick', [startDate, new Date(year + '-' + month + '-' + new Date(year, month, 0).getDate() + ' 23:59:00')]);
                       }
                   }, {
                        text: '上个月',
                        onClick(picker) {
                            var now = new Date();
                            var year = now.getFullYear();
                            var month = now.getMonth();
                            if (month == 0) {
                                month = 12;
                                year = year - 1;
                           }
                            if (month < 10) {
                                month = '0' + month;
                           }
                            var myDate = new Date(year, month, 0);
                            // 上个月第一天
                            var startDate = new Date(year + '-' + month + '-01 00:00:00');
                            // 上个月最后一天
                            var endDate = new Date(year + '-' + month + '-' + myDate.getDate() + ' 23:59:00');
                            picker.$emit('pick', [startDate, endDate]);
                       }
                   }]
               },
//visible:false,  {type: 'checkbox', title: '全选', field:'id', width: 100},
                tableColumn: [
                    {type: 'seq', title: '序号', width: 50},

                    {
                        field:'customer', width: 220, title: '公司名称', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receivables/searchHeard', this.pickerDate)
                       }
                   },
                    {
                        field:'total_amount', width: 100, title: '应收账款', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : '', {digits: 2})
                       }, slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receivables/searchHeard', this.pickerDate)
                       }
                   },{
                        field:'has_pay', width: 100, title: '已收账款', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : '', {digits: 2})
                       }, slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receivables/searchHeard', this.pickerDate)
                       }
                   },{
                        field:'balance', width: 80, title: '欠款', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : '', {digits: 2})
                       }, slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receivables/searchHeard', this.pickerDate)
                       }
                   },{
                        field:'bill_date_kd', width: 100, title: '开单日期',
                        formatter: ({cellValue}) => {
                            return this.formatDate(cellValue, 'yyyy-MM-dd')
                       }, slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receivables/searchHeard', this.pickerDate)
                       }
                   },{
                        field:'bill_id_kd', width: 170, title: '单号', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receivables/searchHeard', this.pickerDate)
                       }
                   },{
                        field:'settlement_days', width: 80, title: '协议欠款天数', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receivables/searchHeard', this.pickerDate)
                        }
                    },{
                        field:'last_give_money_date_count', width: 80, title: '欠款天数', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receivables/searchHeard', this.pickerDate)
                       }
                   },{
                        field:'out_pay_date', width: 80, title: '逾期天数', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receivables/searchHeard', this.pickerDate)
                        }
                    },{
                        field:'sales_man', width: 70, title: '单据业务员', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receivables/searchHeard', this.pickerDate)
                       }
                   },{
                        field:'tax_tate_warehouse_bill2', width: 80, title: '含税情况', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receivables/searchHeard', this.pickerDate)
                       }
                   },{
                        field:'sum_has_money', width: 110, title: '账上有款',
                        titleHelp: {message: '账上有款 = 收定金 + 收款单多收挂账部分(销售开单负数金额)'},
                        formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : '', {digits: 2})
                       }, slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receivables/searchHeard', this.pickerDate)
                       }
                   },{
                        field:'sum_balance', width: 110, title: '合计欠款', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : '', {digits: 2})
                       }, slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receivables/searchHeard', this.pickerDate)
                       }
                   },{
                        field:'actual_balance', width: 110, title: '合计实际欠款', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : '', {digits: 2})
                       }, slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receivables/searchHeard', this.pickerDate)
                       }
                   },{
                        field:'c_sales_man', width: 70, title: '公司业务员', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receivables/searchHeard', this.pickerDate)
                        }
                    },
                ],
                tableDataMain: [],
                filterData: {
                },
                sortData: {
                    "bill_date_kd":"asc",
                    "bill_id_kd":"desc",
                },
                pickerDate: [new Date(new Date().getTime() - 3600 * 1000 * 24 * 90), new Date()],
                tableExport: {
                    // 默认选中类型
                    filename: '应收账款明细',
                    isMerge:true,
                    isFooter:true,
                    type: 'xlsx',
                    isColgroup: true,
                    // 自定义类型
                    types: ['xlsx', 'csv', 'html', 'xml', 'txt']
               },
           }
       },
        methods: {
            printSelectEvent(){
                if (this.$refs.xGrid) {
                    this.$refs.xGrid.print({
                        data: this.$refs.xGrid.getCheckboxRecords()
                    })
                }
            },
            printEvent2(){
                const $table = this.$refs.xGrid
                if ($table) {
                    $table.openPrint()
                }
            },
            mergeRowMethod({row, _rowIndex, column, visibleData}){
                /*{row: 1, col: 9, rowspan: 2, colspan: 1}*/
                const fields = ['sum_balance']
                const cellValue = row['customer']
                //console.log('column.property',column.property)
                if (cellValue && fields.includes(column.property)) {
                    const prevRow = visibleData[_rowIndex - 1]
                    console.log('prevRow',prevRow)
                    let nextRow = visibleData[_rowIndex + 1]
                    if (prevRow && prevRow['customer'] === cellValue) {
                        return {rowspan: 0, colspan: 0}
                   } else {
                        let countRowspan = 1
                        while (nextRow && nextRow['customer'] === cellValue) {
                            nextRow = visibleData[++countRowspan + _rowIndex]
                       }
                        if (countRowspan > 1) {
                            return {rowspan: countRowspan, colspan: 1}
                       }
                   }
               }
           },
            openReceipt(row){
                this.$router.push({path: '/receipt', query: {cash_type_id: row.cash_type_id,year:row.year,month:row.month,type:row.cash_type}})
           },

            getNumberDefaultZero(obj) {
                //console.log("getNumberDefaultZero : " + obj);
                if (obj == null || typeof (obj) == 'undefined' || isNaN(obj)) {
                    return Number(0)
               } else {
                    return this.$XEUtils.commafy(Number(obj), {digits: 2})
               }
           },
            sumNum(list, field) {
                let count = Number(0)
                list.forEach(item => {
                    count = this.NumberAdd(count, item[field]);
               })
                return this.getNumberDefaultZero(count)
           },

            footerMethod({columns, data}) {
                const sums = []
                columns.forEach((column, columnIndex) => {
                    if (columnIndex === 0) {
                        sums.push('合计')
                   } else {
                        if (column.property === 'total_amount' || column.property === 'balance'|| column.property === 'sum_balance'|| column.property === 'actual_balance'

                        ) {
                            sums.push(this.sumNum(data, column.property))
                       } else {
                            sums.push('')
                       }
                   }
               })
                //this.autoComputed();
                // 返回一个二维数组的表尾合计
                return [sums]
           },

            searchReceivables() {
                this.tablePage.currentPage = 1;
                this.getReceivables();
           },
            getReceivables() {
                const loading = this.$loading({
                    lock: true,
                    text: '饮茶先啦，数据查询中，请耐心等待!',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
               });
                let searchUrl = '/admin/receivables/search'
                this.$axios({
                    method: 'POST',
                    url: searchUrl,
                    data: {
                        currentPage: this.tablePage.currentPage,
                        pageSize: this.tablePage.pageSize,
                        filterData: this.filterData,
                        sortData: this.sortData,
                        pickerDate: this.pickerDate,
                        formData:this.formData
                   }
               }).then((response) => {         //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    loading.close();
                    if (response.status === 200) {
                        this.tableDataMain = response.data.page.list
                        this.tablePage.pageSize = response.data.page.pageSize
                        this.tablePage.total = response.data.page.totalRow
                        this.tablePage.currentPage = response.data.page.pageNumber
                        this.mergeCells = response.data.mergeCells
                   }
               }).catch((error) => {
                    loading.close();
                    console.log(error)
               });
           },
            handlePageChange({currentPage, pageSize}) {
                this.tablePage.currentPage = currentPage
                this.tablePage.pageSize = pageSize
                this.getReceivables();
           },
            openTheWarehouse(row) {
                //this.$router.push({path: '/transferSlip', query: {bill_id_kd : row.bill_id_kd}})
                this.$_openType({path: '/transferSlip', query: {bill_id_kd : row.bill_id_kd}})
           },
            printTransferSlip(row) {
                this.$router.push({path: '/printTransferSlip', query: {bill_id_kd : row.bill_id_kd}})
           },

            formatDate(value, format) {
                return XEUtils.toDateString(value, format)
           },
            tableRowClassName({rowIndex}) {
                if (rowIndex % 2 === 1) {
                    return 'success-row';
               } else {
                    return '';
               }
           },
            cellClickEvent({row,rowIndex}){
                if (this.$refs.xGrid.isCheckedByCheckboxRow(row)){
                    this.$refs.xGrid.setCheckboxRow([this.tableDataMain[rowIndex]], false)
                }else{
                    this.$refs.xGrid.setCheckboxRow([this.tableDataMain[rowIndex]], true)
                }
            },
       },computed: {

       },
        created() {

            this.getReceivables();
            this.$nextTick(() => {
                // 手动将表格和工具栏进行关联
                this.$refs.xGrid.connect(this.$refs.xToolbar2)
           })
       }
   };


</script>

<style scoped>
    .header-wrapepr {
        display: flex;
        flex-direction: row;
        justcontent: space-between
   }

    .el-date-editor.el-input, .el-date-editor.el-input__inner {
        width: 130px;
   }

    .el-form-item__content .el-input {
        width: 130px;
   }

    /deep/ .el-input--suffix .el-input__inner {
        padding-right: initial !important;
   }

    /deep/ input[popperclass="myAutocomplete"] {
        padding: 3px !important;
   }

    /deep/ .el-input__suffix {
        right: 0px !important;
   }
    /deep/ .vxe-cell-help-icon.vxe-icon--question{
        display: -webkit-box !important;
        position: absolute !important;
    }
</style>
